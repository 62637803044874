import React from 'react'
import {
  List,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  TextInput,
  SelectInput,
  ReferenceField,
  ReferenceInput,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'

export const TopicList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="category_id" reference="categories">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" />
    </Datagrid>
  </List>
)

export const TopicEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="category_id" reference="categories">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="title" />
      <RichTextInput source="description" />
    </SimpleForm>
  </Edit>
)
