import React from 'react'
import {
  List,
  Edit,
  Datagrid,
  TextField,
  TextInput,
  UrlField,
  SimpleForm,
} from 'react-admin'

export const VideoList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <UrlField source="url" />
    </Datagrid>
  </List>
)

export const VideoEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="url" />
    </SimpleForm>
  </Edit>
)
