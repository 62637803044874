import React from 'react'
import {
  List,
  Edit,
  Datagrid,
  TextField,
  TextInput,
  UrlField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'

export const ReadingList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="lesson_id" reference="lessons">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" />
      <UrlField source="url" />
    </Datagrid>
  </List>
)

export const ReadingEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="lesson_id" reference="lessons">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="title" />
      <RichTextInput source="description" />
      <TextInput source="url" />
    </SimpleForm>
  </Edit>
)
