import React from 'react'
import {
  List,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  ReferenceField,
  ReferenceInput,
  TextInput,
  NumberInput,
  SelectInput,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'

export const LessonList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField source="course_id" reference="courses">
        <TextField source="title" />
      </ReferenceField>
      <TextField source="title" />
    </Datagrid>
  </List>
)

export const LessonEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="course_id" reference="courses">
        <SelectInput optionText="id" />
      </ReferenceInput>
      <TextInput source="title" />
      <NumberInput source="order" />
      <RichTextInput source="description" />
    </SimpleForm>
  </Edit>
)
