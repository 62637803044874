import React, { Component } from 'react'
import { Admin, Resource, EditGuesser } from 'react-admin'
import hasuraProvider from 'ra-data-hasura'

import { CourseList, CourseEdit } from './models/Course'
import { CategoryList, CategoryEdit } from './models/Category'
import { LessonList, LessonEdit } from './models/Lesson'
import { ReadingList, ReadingEdit } from './models/Reading'
import { TopicList, TopicEdit } from './models/Topic'
import { VideoList, VideoEdit } from './models/Video'
// import {List} from './models/'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-hasura-admin-secret': 'learn12321L',
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <Admin
          dataProvider={hasuraProvider(
            'http://learn-anything.herokuapp.com',
            headers,
          )}
        >
          <Resource name="categories" list={CategoryList} edit={CategoryEdit} />
          <Resource name="courses" list={CourseList} edit={CourseEdit} />
          <Resource name="lessons" list={LessonList} edit={LessonEdit} />
          <Resource name="readings" list={ReadingList} edit={ReadingEdit} />
          <Resource name="topics" list={TopicList} edit={TopicEdit} />
          <Resource name="videos" list={VideoList} edit={VideoEdit} />
        </Admin>
      </div>
    )
  }
}

export default App
