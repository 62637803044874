import React from 'react'
import {
  List,
  Edit,
  SimpleForm,
  Datagrid,
  TextField,
  TextInput,
  ReferenceInput,
  NumberInput,
  SelectInput,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'

export const CourseList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
    </Datagrid>
  </List>
)

export const CourseEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="topic_id" reference="topics">
        <SelectInput optionText="title" />
      </ReferenceInput>
      <TextInput source="title" />
      <NumberInput source="level" />
      <RichTextInput source="description" />
    </SimpleForm>
  </Edit>
)
